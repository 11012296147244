import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { SEO, Text } from '../components/common';
import HeroSection from '../components/HeroSection';
import { ServicePage } from '../@types/types';
import PriceSection from '../components/PriceSection';
import ThreeSquareSection from '../components/ThreeSquareSection';
import { NextPage } from '../components/SVG';
import InfoSection from '../components/InfoSection';
import GridBlock from '../components/ThreeSquareSection/GridBlock';

type Props = {
  data: { page: ServicePage; arrow: { publicURL: string } };
  location: Location;
};

const Services = ({ data, location }: Props) => {
  const {
    page: {
      MetaData,
      PageTopSection,
      Instructions,
      PriceInfo,
      PriceTable,
      FiveBlockSection: {
        FirstSectionTitle,
        FirstSectionText,
        FirstSectionPicture,
        SecondSectionTitle,
        SecondSectionText,
        SecondSectionPicture,
        ThirdSectionTitle,
        ThirdSectionText,
        ThirdSectionPicture,
        FourthSectionTitle,
        FourthSectionText,
        FourthSectionPicture,
        FifthSectionTitle,
        FifthSectionText,
        FifthSectionPicture,
      },
      FeaturedServices,
    },
    arrow,
  } = data;

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData.MetaTitle}
        description={MetaData.MetaDescription}
        keywords={MetaData.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />
      <GridBlock
        title={FirstSectionTitle}
        content={FirstSectionText}
        picture={FirstSectionPicture}
        boxColor={'lightBlue'}
        buttons={FeaturedServices.map((service) => (
          <ServiceButton key={service.slug} href={`/palvelut/${service.slug}`}>
            <Text variant="title-4">{service.Name}</Text>
            <NextPage width="34" />
          </ServiceButton>
        ))}
      />
      <GridBlock
        title={SecondSectionTitle}
        content={SecondSectionText}
        picture={SecondSectionPicture}
        boxColor={'lightBlue'}
        buttons={
          <ServiceButton href="/perhepalvelut/">
            <Text variant="title-4">Lue lisää</Text>
            <NextPage width="34" />
          </ServiceButton>
        }
        reverse
      />
      <GridBlock
        title={ThirdSectionTitle}
        content={ThirdSectionText}
        picture={ThirdSectionPicture}
        boxColor={'lightBlue'}
        buttons={
          <ServiceButton href="/palvelut/psykoterapia">
            <Text variant="title-4">Lue lisää</Text>
            <NextPage />
          </ServiceButton>
        }
      />
      <GridBlock
        title={FourthSectionTitle}
        content={FourthSectionText}
        picture={FourthSectionPicture}
        boxColor={'lightBlue'}
        reverse
        buttons={
          <ServiceButton href="/tyonohjaus">
            <Text variant="title-4">Lue lisää</Text>
            <NextPage />
          </ServiceButton>
        }
      />
      <GridBlock
        title={FifthSectionTitle}
        content={FifthSectionText}
        picture={FifthSectionPicture}
        boxColor={'lightBlue'}
        buttons={
          <ServiceButton href="/kela-kuntoutukset/">
            <Text variant="title-4">Lue lisää</Text>
            <NextPage />
          </ServiceButton>
        }
      />

      <InfoSection
        header="Ohjeet"
        instructions={Instructions}
        bgColor="white"
      />
      <PriceSection
        header="Hinnasto"
        priceData={PriceTable}
        bgColor="blue"
        width="200"
        text={PriceInfo}
        image={arrow.publicURL}
      />
    </>
  );
};

export default Services;

export const serviceQuery = graphql`
  query ServicePageQuery {
    page: strapiPalvelutJaHinnasto {
      ...strapiPalvelutJaHinnastoFragment
    }
    arrow: file(name: { eq: "blue-arrow" }) {
      publicURL
    }
  }
`;

export const ServiceButton = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  padding: 10px 20px;
  text-decoration: none;
  margin-bottom: ${(props) => props.theme.spacing.default};
`;
